import { createContext, useReducer, FC, Dispatch } from 'react'

import reducer from './reducer'

interface State {
  scaleup: boolean
}

const initialState = {
  scaleup: false
}

export const DataLayerContext = createContext<{
  state: State
  dispatch: Dispatch<any>
}>({
  state: initialState,
  dispatch: () => null
})

const DataLayer: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <DataLayerContext.Provider value={{ state, dispatch }}>
      {children}
    </DataLayerContext.Provider>
  )
}

export default DataLayer
