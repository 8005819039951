import { FC } from 'react'

const LoadingScreen: FC = () => {
  return (
    <>
      <div className="load">
        <div className="dot"></div>
        <div className="outline">
          <span></span>
        </div>
      </div>
    </>
  )
}

export default LoadingScreen
