import { ACTIVATE_SCALE_UP, DEACTIVATE_SCALE_UP } from './constants'

interface State {
  scaleup: boolean
}

interface Action {
  type: string
}

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ACTIVATE_SCALE_UP:
      return {
        ...state,
        scaleup: true
      }
    case DEACTIVATE_SCALE_UP:
      return {
        ...state,
        scaleup: false
      }
    default:
      return state
  }
}

export default reducer
