import { FC, lazy, Suspense, useEffect, useState, useContext } from 'react'
import { HashRouter, Switch, Route } from 'react-router-dom'

import LoadingScreen from './Screens/LoadingScreen'

import { DataLayerContext } from './Context/context'

const Home = lazy(() => {
  return Promise.all([
    import('./Screens/HomeScreen'),
    new Promise((resolve) => setTimeout(resolve, 3000))
  ]).then(([moduleExports]) => moduleExports)
})
const Skills = lazy(() => import('./Screens/SkillsScreen'))
const Experience = lazy(() => import('./Screens/ExperienceScreen'))
const Work = lazy(() => import('./Screens/WorkScreen'))
const Contact = lazy(() => import('./Screens/ContactScreen'))

const SidePanel = lazy(() => import('./Components/SidePanel'))

const Card = lazy(() => import('./Components/Card'))

const App: FC = () => {
  const [top, setTop] = useState(0)
  const [left, setLeft] = useState(0)

  const { state } = useContext(DataLayerContext)

  useEffect(() => {
    const cursorHandler = (e: MouseEvent) => {
      setTop(e.pageY)
      setLeft(e.pageX)
    }

    document.addEventListener('mousemove', cursorHandler)
    return () => {
      document.removeEventListener('mousemove', cursorHandler)
    }
  }, [])

  return (
    <>
      <HashRouter>
        <Suspense fallback={<LoadingScreen />}>
          <SidePanel />
          <div className="nodemon">
            <Switch>
              <Route path="/" component={Home} exact />
              <Route path="/skills" component={Skills} />
              <Route path="/experience" component={Experience} />
              <Route path="/work" component={Work} />
              <Route path="/contact" component={Contact} />
              <Route path="/loader" component={LoadingScreen} />
              <Route path="/card" component={Card} />
            </Switch>
          </div>
          <div
            style={{
              top: `${top}px`,
              left: `${left}px`,
              height: state.scaleup ? '8em' : '1em',
              width: state.scaleup ? '8em' : '1em'
            }}
            className="cursor"
          />
        </Suspense>
      </HashRouter>
    </>
  )
}

export default App
